export const enum typeProcessNft {
  buy = 1,
  sale = 2,
  change = 3,
  cancel = 4,
  bid = 5,
  cancelAuction = 6,
  endAuction = 7,
  auction = 8,
  offer = 9,
  acceptoffer = 10,
  canceloffer = 11,
}
export class NFTProcssModel {
  onchainId: number;
  expired: number;
  type: typeProcessNft;
  saleAddress: string;
  ownerAddress: string;
  nftAddress: string;
  price: number;
  name: string;
  collectionId: number;
  offerId: number;
  constructor() {
    this.onchainId = 0;
    this.expired = 0;
    this.type = typeProcessNft.buy;
    this.saleAddress = "";
    this.price = 0;
    this.name = "";
    this.ownerAddress = "";
    this.nftAddress = "";
    this.collectionId = 0;
    this.offerId = 0;
  }
}
