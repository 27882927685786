import { NFTCollection } from "@/models/NFTCollection";
import { NFTProcssModel } from "@/models/NFTProcssModel";
import { defineStore } from "pinia";
import { DefaultStorage } from "@/utils/localstorage";
import { getExpireTokenUnixUTC, getUnixLocalNow } from "@/utils/date";

const storage = new DefaultStorage();

export const useTokenStore = defineStore("tokenauth", {
  state: () => ({ token: "", expired: null }),

  actions: {
    async getToken() {
      this.token = await getTokenStorage();
      return this.token;
    },
    async checkToken() {
      this.expired = await getTokenExpireStorage();
      const now = getUnixLocalNow();
      if (now < this.expired) {
        return await this.getToken();
      } else {
        await this.removeToken();
        return "";
      }
    },

    async addToken(token) {
      const unix = getExpireTokenUnixUTC();
      await storage.setItem("api-access-token", token);
      await storage.setItem("api-access-token-expired", unix);
    },
    async removeToken() {
      await storage.removeItem("api-access-token");
      await storage.removeItem("api-access-token-expired");
    },
  },
});

async function getTokenStorage() {
  const stored = await storage.getItem("api-access-token");
  if (stored) {
    return stored;
  } else return "";
}

async function getTokenExpireStorage() {
  const stored = await storage.getItem("api-access-token-expired");
  if (stored) {
    return Number(stored);
  } else return 0;
}

export const useAirdropStore = defineStore("airdrop", {
  state: () => ({ wallet: "" }),
  actions: {
    getWallet() {
      this.wallet;
    },
  },
});

export const useCollectStore = defineStore("collection", {
  state: () => ({ collect: new NFTCollection() }),
  actions: {
    getCollect() {
      this.collect;
    },
  },
});

export const useMintStore = defineStore("mint", {
  state: () => ({ mint: false }),
  actions: {
    getMint() {
      this.mint;
    },
  },
});

async function getLocalStorage() {
  const stored = await storage.getItem("process-nft");
  if (stored) {
    try {
      return JSON.parse(stored);
    } catch (ex) {
      return new Array<NFTProcssModel>();
    }
  } else return new Array<NFTProcssModel>();
}

export const useNftProcessStore = defineStore("nftprocess", {
  state: () => ({ nfts: new Array<NFTProcssModel>() }),

  actions: {
    async getLocal() {
      this.nfts = await getLocalStorage();
    },
    async add(payload: NFTProcssModel) {
      const index = this.nfts.findIndex(
        (item) => item.onchainId == payload.onchainId
      );

      if (index == -1) {
        this.nfts.push(payload);
        await storage.setItem("process-nft", JSON.stringify(this.nfts));
      }
    },
    async remove(onchainId) {
      const index = this.nfts.findIndex((item) => item.onchainId == onchainId);
      if (index !== -1) {
        this.nfts.splice(index, 1);

        await storage.setItem("process-nft", JSON.stringify(this.nfts));
      }
    },
    async removeAll() {
      await storage.removeItem("process-nft");
      this.nfts = new Array<NFTProcssModel>();
    },
  },
});

export const useAlertStore = defineStore("alert", {
  state: () => ({ alert: false, message: "", type: "success" }),
});
