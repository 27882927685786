export default [
  {
    path: "/mint",
    name: "mintnft",
    component: () => import("@/views/nft/mint.vue"),
    meta: {
      isFooter: false,
      isMyNFT: false,
      pageName: "MINT NFT",
    },
  },
  {
    path: "/locknft",
    name: "locknft",
    component: () => import("@/views/nft/lock.vue"),
    meta: {
      isFooter: false,
      isMyNFT: false,
      pageName: "Lock NFT",
    },
  },
];
