import axios from "axios";
import { JettonMaster } from "@ton/ton";
import { Address, beginCell, fromNano } from "@ton/core";
import { getClientV2, getClientV3 } from "./useClientV2";
import { PARAMSV2 } from "../constantsV2";
export async function getTonPrice() {
  try {
    const response = await axios.get(
      "https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest?symbol=TON&convert=USD&CMC_PRO_API_KEY=b7ee9745-c0d3-419b-87d4-28f4068afd0f"
    );
    const data = response.data;
    const price = data.data.TON.quote.USD.price;
    console.log(`The price of TON is $${price}`);
    console.log(data);
    return price;
  } catch (error) {
    console.error("Error getting TON price:", error);
    return 0;
  }
}

export async function getMaster(asset) {
  try {
    const response = await axios.get(
      `https://toncenter.com/api/v3/jetton/masters?address=${asset}&limit=10&offset=0`,
      {
        headers: {
          "X-Api-Key": PARAMSV2.apiKeyAuction[0],
        },
      }
    );
    const data = response.data;

    console.log(data);
    return data;
  } catch (error) {
    console.error("Error getting TON price:", error);
    return 0;
  }
}

export async function getBalance(address): Promise<string> {
  const client = await getClientV2();
  const balance = await client.getBalance(address);
  return fromNano(balance);
}

export async function getWalletData(address): Promise<string> {
  const client = await getClientV2();
  const balance = await client.getBalance(address);
  return fromNano(balance).toString();
}

export async function getTokens(address, tokenAddress) {
  const client = await getClientV3();
  const jettonMasterAddress = Address.parse(tokenAddress);
  const userAddress = Address.parse(address);
  const jettonMaster = client.open(JettonMaster.create(jettonMasterAddress));
  const jettonUserWalletAdress = await jettonMaster.getWalletAddress(
    userAddress
  );

  try {
    const { stack } = await client.runMethod(
      jettonUserWalletAdress,
      "get_wallet_data"
    );
    return {
      balance: fromNano(stack.readNumber()),
      owner: stack.readAddress(),
    };
  } catch (ex) {
    return {
      balance: "0",
      owner: "",
    };
  }
}

export async function jettonUserWalletAdress(contractAddr, owner) {
  const client = await getClientV2();
  const jettonMasterAddress = Address.parse(contractAddr);
  const userAddress = Address.parse(owner);
  const jettonMaster = client.open(JettonMaster.create(jettonMasterAddress));
  const result = await jettonMaster.getWalletAddress(userAddress);

  return result;
}
