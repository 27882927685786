import { apiCaller } from "@/api/api-helper";
import { PARAMSV2 } from "@/utils/constantsV2";

export class ConnectService {
  public connect(
    token: string,
    tgUserId: string,
    walletAddress: string,
    isConnect: boolean,
    clientId: string | null,
    merchantId: number | null
  ) {
    let headers;
    if (token && token.length > 0) {
      headers = {
        Authorization: "Bearer " + token,
      };
    } else {
      headers = {
        "api-key": PARAMSV2.apiKeyConnect,
      };
    }

    return apiCaller(
      "post",
      "/v1/account/connect",
      {
        tgUserId: tgUserId,
        walletAddress: walletAddress,
        isConnect: isConnect,
        clientId: clientId,
        merchantId: merchantId,
      },
      headers,
      false
    );
  }

  public presaleConnect(
    tgUserId: string,
    walletAddress: string,
    presaleId: number
  ) {
    const headers = {
      "api-key": PARAMSV2.apiKeyConnect,
    };
    return apiCaller(
      "post",
      "/v1/account/presale/connect",
      {
        tgUserId: tgUserId,
        walletAddress: walletAddress,
        presaleId: presaleId,
      },
      headers,
      false
    );
  }
}
