export class NFTAttributes {
  traitType: string;
  value: string;
  constructor() {
    this.traitType = "";
    this.value = "";
  }
}

export class NFTConfig {
  id: number;
  nftItem: string;
  seller: string;
  price: number;
  minPrice: number;
  feeTo: string;
  feeToPct: number;
  royaltyTo: string;
  royaltyPct: number;

  constructor(m?: NFTConfig) {
    this.id = 0;
    this.nftItem = "";
    this.seller = "";
    this.price = 0;
    this.minPrice = 0;
    this.feeTo = "";
    this.feeToPct = 0;
    this.royaltyTo = "";
    this.royaltyPct = 0;

    if (m) {
      this.id = m.id;
      this.nftItem = m.nftItem;
      this.seller = m.seller;
      this.price = m.price;
      this.minPrice = m.minPrice;
      this.feeTo = m.feeTo;
      this.feeToPct = m.feeToPct;
      this.royaltyTo = m.royaltyTo;
      this.royaltyPct = m.royaltyPct;
    }
  }
}

export class NFTProgress {
  isCompleted: boolean;
  createdAt: number;
  soldAt: number;

  constructor(m?: NFTProgress) {
    this.isCompleted = false;
    this.createdAt = 0;
    this.soldAt = 0;

    if (m) {
      this.isCompleted = m.isCompleted;
      this.createdAt = m.createdAt;
      this.soldAt = m.soldAt;
    }
  }
}
export class NFTBid {
  bidAmount: number;
  bidTime: string;
  bidder: string;
  id: number;
  constructor(m?: NFTBid) {
    this.bidAmount = 0;
    this.bidTime = "";
    this.bidder = "";
    this.id = 0;
    if (m) {
      this.bidAmount = m.bidAmount;
      this.bidTime = m.bidTime;
      this.bidder = m.bidder;
      this.id = Number(m.id);
    }
  }
}

export class NFTOffer {
  id: number;
  expiredAt: string;
  offeror: string;
  offerAddress: string;
  isCompleted: boolean;
  price: number;
  constructor(m?: NFTOffer) {
    this.id = 0;
    this.expiredAt = "";
    this.offeror = "";
    this.offerAddress = "";
    this.isCompleted = false;
    this.price = 0;
    if (m) {
      this.id = Number(m.id);
      this.expiredAt = m.expiredAt;
      this.offeror = m.offeror;
      this.isCompleted = m.isCompleted;
      this.offerAddress = m.offerAddress;
      this.price = m.price;
    }
  }
}
export class NFTSale {
  createdAt: string;
  id: number;
  isComplete: boolean;
  nftAddress: string;
  price: number;
  saleAddress: string;
  seller: string;
  type: string;
  reservePrice: number;
  buyoutPrice: number;
  endTime: string;
  hasBid: boolean;
  incrementPct: number | null;
  latestBid: NFTBid | null;
  bids: Array<NFTBid>;

  status: number | null;
  constructor(m?: NFTSale) {
    this.createdAt = "";
    this.id = 0;
    this.isComplete = false;
    this.nftAddress = "";
    this.price = 0;
    this.saleAddress = "";
    this.seller = "";
    this.type = "SALE";
    this.reservePrice = 0;
    this.buyoutPrice = 0;
    this.endTime = "";
    this.hasBid = false;
    this.incrementPct = null;
    this.latestBid = null;
    this.bids = new Array<NFTBid>();

    this.status = null;
    if (m) {
      this.createdAt = m.createdAt;
      this.id = m.id;
      this.isComplete = m.isComplete;
      this.nftAddress = m.nftAddress ? m.nftAddress : "";
      this.price = m.price;
      this.saleAddress = m.saleAddress ? m.saleAddress : "";
      this.seller = m.seller ? m.seller : "";
      this.type = m.type;
      this.reservePrice = m.reservePrice;
      this.buyoutPrice = m.buyoutPrice;
      this.endTime = m.endTime;
      this.hasBid = m.hasBid;
      this.incrementPct = m.incrementPct ? m.incrementPct : 0;
      this.latestBid = m.latestBid != null ? new NFTBid(m.latestBid) : null;
      this.bids =
        m.bids && m.bids.length > 0
          ? m.bids.map((x) => new NFTBid(x))
          : new Array<NFTBid>();

      this.status = null;
    }
  }
}

export class NFTAuction {
  status: number;
  constructor(m?: NFTAuction) {
    this.status = 0;
    if (m) {
      this.status = m.status;
    }
  }
}

export class NFTInfoModel {
  address: string;
  id: number;
  onchainId: number;
  collectionId: number;
  name: string;
  description: string;
  image: string;
  slug: string;
  price: number;
  attributes?: Array<NFTAttributes>;
  edition: number;
  metadata: string;
  owner: string;
  config: NFTConfig;
  progress: NFTProgress;
  forSale: boolean;
  sale: NFTSale;
  offers: Array<NFTOffer>;
  latestBid: NFTBid | null;
  rarity: string;
  constructor(m?: NFTInfoModel) {
    this.address = "";
    this.id = 0;
    this.onchainId = 0;
    this.collectionId = 0;
    this.name = "";
    this.description = "";
    this.image = "";
    this.slug = "";
    this.price = 0;
    this.edition = 0;
    this.metadata = "";
    this.attributes = new Array<NFTAttributes>();
    this.owner = "";
    this.config = new NFTConfig();
    this.progress = new NFTProgress();
    this.forSale = false;
    this.sale = new NFTSale();
    this.offers = new Array<NFTOffer>();
    this.latestBid = null;
    this.rarity = "";
    if (m) {
      this.address = m.address ? m.address : "";
      this.id = Number(m.id);
      this.onchainId = Number(m.onchainId);
      this.collectionId = m.collectionId;
      this.name = m.name;
      this.description = m.description;
      this.image = m.image ? m.image : "";
      this.slug = m.slug;
      this.price = m.price;
      this.attributes = m.attributes
        ? m.attributes
        : new Array<NFTAttributes>();
      this.edition = m.edition;
      this.metadata =
        m.image && m.image.indexOf("gateway.pinata.cloud") > -1
          ? "Ipfs"
          : "Centralized";
      this.config = new NFTConfig();
      this.progress = new NFTProgress();
      this.owner = m.owner;
      this.forSale = false;
      this.sale = m.sale ? new NFTSale(m.sale) : new NFTSale();
      this.offers =
        m.offers && m.offers.length > 0
          ? m.offers.map((x) => new NFTOffer(x))
          : new Array<NFTOffer>();
      this.rarity = getRarity(m.attributes, m.onchainId);
      if (m.latestBid) {
        this.latestBid = new NFTBid(m.latestBid);
      }
    }
  }
}

function getRarity(attributes, onchainId) {
  if (attributes && attributes.length > 0) {
    try {
      const rarity = attributes.find((f) => f.traitType == "Rarity");
      if (rarity != undefined && rarity.value) {
        const arr = rarity.value.split(" ");
        let str = "";
        arr.forEach((element) => {
          str += element.substring(0, 1).toUpperCase();
        });
        return str;
      } else {
        return "";
      }
    } catch (ex) {
      return "";
    }
  } else return "";
}

export enum AucionStatus {
  pending = 0,
  active = 1,
  cancelled = 2,
  ended = 3,
  endedNoBids = 4,
  finalized = 5,
  finalizedNoBids = 6,
}
