import moment from "moment";
/**
 * Convert Date to "4 Oct 2023" format
 *
 * @param date - Date to format
 * @param short - Whether to use short month name "4/06/23" instead of "4 Jun 2023"
 */
export function formatDate(date: Date, short: boolean = false): string {
  const day = date.getDate();

  if (!short) {
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  const month = date.toLocaleString("default", { month: "numeric" });
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function getUnixNow() {
  return moment().utc().unix();
}

export function getUnixLocalNow() {
  return moment().unix();
}

/**
 * Get Uix UTC from Date UTC
 * @param date - UTC
 * @returns
 */
export function getUnixUTCFromUTC(date: string) {
  return moment(date).unix();
}

export function getUnixFromUTC(date: string) {
  return moment(date).local().unix();
}

export function getExpireTokenUnixUTC() {
  return moment().endOf("day").add(5, "month").unix();
}

/**
 *
 * @param date default format
 * @returns
 */
export function getStringLocalFromUnix(unix: number) {
  const date = moment.unix(unix).toDate();

  return new Intl.DateTimeFormat("en-US", {
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    timeZone: "UTC",
    timeZoneName: "short",
  }).format(date);
}

export function getDateFromUnix(unix: number) {
  const date = moment.unix(unix).utc().format("YYYY-MM-DDTHH:mm:ss");
  return date;
}

export function getLocalDateFromUnixUTC(unix: number) {
  return moment.unix(unix).local().format("YYYY-MM-DD HH:mm:ss");
}

export function getDateFromUnixUTC(unix: number) {
  return moment.unix(unix).utc().format("YYYY-MM-DD HH:mm:ss") + " UTC";
}
export function getShortDateFromUnixUTC(unix: number) {
  return moment.unix(unix).utc().format("YYYY-MM-DD");
}
export function getShortDateFromDateUTC(date: string) {
  return moment.utc(date).format("YYYY-MM-DD");
}
