import { nftCaller } from "@/api/nft-helper";
import { useTokenStore } from "@/store";
import { PARAMSV2 } from "@/utils/constantsV2";
export class NftService {
  public getConversion(conversionId: string) {
    const headers = {
      "api-key": PARAMSV2.apiKeyConnect,
    };
    return nftCaller("get", `/v1/conversion/${conversionId}`, {}, headers);
  }
  public preConversion(
    conversionId: string,
    price: number,
    ownerAddress: string
  ) {
    const headers = {
      "api-key": PARAMSV2.apiKeyConnect,
    };
    return nftCaller(
      "post",
      `/v1/conversion/pre-conversion`,
      { conversionId: conversionId, price: price, ownerAddress: ownerAddress },
      headers
    );
  }

  public async preLock(
    collectionId: string,
    onchainId: string,
    ownerAddress: string
  ) {
    const headers = {
      "api-key": PARAMSV2.apiKeyConnect,
    };

    return nftCaller(
      "get",
      `/v1/lock/pre-lock?collectionId=${collectionId}&onchainId=${onchainId}&ownerAddress=${ownerAddress}`,
      {},
      headers
    );
  }

  /**
   * list nft of collection
   * @param collectionId
   * @param offset
   * @param limit
   * @returns
   */
  public ntfList(
    collectionId: number,
    offset: number,
    limit: number,
    owner: string | null,
    search: string,
    sortOrder: string,
    sortBy: string,
    filter
  ) {
    const headers = {
      "api-key": PARAMSV2.apiKeyConnect,
    };
    let pathOwner = "";
    if (owner) {
      pathOwner = `&owner=${owner}`;
    }
    return nftCaller(
      "get",
      `/v1/nft/collection/${collectionId}/item/list?offset=${offset}&limit=${limit}${pathOwner}&search=${search}&sortOrder=${sortOrder}&sortBy=${sortBy}&filter=${filter}`,
      {},
      headers
    );
  }

  public myNtfList(
    walletAddress: string,
    collectionId: Array<number>,
    offset: number,
    limit: number,
    search: string,
    sortOrder: string,
    sortBy: string,
    hasOffer: boolean | null
  ) {
    const headers = {
      "api-key": PARAMSV2.apiKeyConnect,
    };
    const collect = collectionId.map((element) => "collectionId=" + element);
    let offer = "";
    if (hasOffer) {
      offer = `&hasOffers=${hasOffer}`;
    }
    return nftCaller(
      "get",
      `/v1/nft/owner/${walletAddress}/item/list?offset=${offset}&limit=${limit}&${collect.join(
        "&"
      )}&search=${search}&sortOrder=${sortOrder}&sortBy=${sortBy}${offer}`,
      {},
      headers
    );
  }

  public myOffer(walletAddress: string) {
    const headers = {
      "api-key": PARAMSV2.apiKeyConnect,
    };

    return nftCaller(
      "get",
      `/v1/nft/offer/offeror/${walletAddress}`,
      {},
      headers
    );
  }

  public myBid(walletAddress: string) {
    const headers = {
      "api-key": PARAMSV2.apiKeyConnect,
    };

    return nftCaller("get", `/v1/nft/bid/bidder/${walletAddress}`, {}, headers);
  }

  public collectionList(offset: number, limit: number) {
    const headers = {
      "api-key": PARAMSV2.apiKeyConnect,
    };
    return nftCaller(
      "get",
      `/v1/nft/collection/list?offset=${offset}&limit=${limit}`,
      {},
      headers
    );
  }

  /**
   * get detail collect
   * @param collectionId
   * @returns
   */
  public collectionDetail(collectionId: number) {
    const headers = {
      "api-key": PARAMSV2.apiKeyConnect,
    };
    return nftCaller("get", `/v1/nft/collection/${collectionId}`, {}, headers);
  }

  /**
   * get detail nft
   * @param itemId
   * @returns
   */
  public nftDetail(collectionId: number, onchainId: number) {
    const headers = {
      "api-key": PARAMSV2.apiKeyConnect,
    };
    return nftCaller(
      "get",
      `/v1/nft/collection/${collectionId}/item/${onchainId}`,
      {},
      headers
    );
  }

  public nftDetailById(itemId: number) {
    const headers = {
      "api-key": PARAMSV2.apiKeyConnect,
    };
    return nftCaller("get", `/v1/nft/collection/item/${itemId}`, {}, headers);
  }

  public async preSale(
    nftAddress: string,
    sellerAddress: string,
    price: number,
    token: string
  ) {
    let headers;
    if (token && token.length > 0) {
      headers = {
        Authorization: "Bearer " + token,
      };
    } else {
      headers = {
        "api-key": PARAMSV2.apiKeyConnect,
      };
    }

    return nftCaller(
      "get",
      `/v1/nft/pre-listing?nftAddress=${nftAddress}&sellerAddress=${sellerAddress}&price=${price}`,
      {},
      headers
    );
  }
  public preAuction(
    nftAddress: string,
    sellerAddress: string,
    reservePrice: number,
    buyoutPrice: number,
    incrementPct: number,
    endTime: string,
    token: string
  ) {
    let headers;
    if (token && token.length > 0) {
      headers = {
        Authorization: "Bearer " + token,
      };
    } else {
      headers = {
        "api-key": PARAMSV2.apiKeyConnect,
      };
    }

    let query = `nftAddress=${nftAddress}&sellerAddress=${sellerAddress}&reservePrice=${reservePrice}&endTime=${endTime}`;
    if (buyoutPrice > 0) {
      query += `&buyoutPrice=${buyoutPrice}`;
    }
    if (incrementPct > 0) {
      query += `&incrementPct=${incrementPct}`;
    }
    return nftCaller("get", `/v1/nft/pre-auction?${query}`, {}, headers);
  }

  public preOffer(
    nftAddress: string,
    offeror: string,
    price: number,
    expiredAt: string,
    token: string
  ) {
    let headers;
    if (token && token.length > 0) {
      headers = {
        Authorization: "Bearer " + token,
      };
    } else {
      headers = {
        "api-key": PARAMSV2.apiKeyConnect,
      };
    }

    const query = `nftAddress=${nftAddress}&offeror=${offeror}&price=${price}&expiredAt=${expiredAt}`;

    return nftCaller("get", `/v1/nft/pre-offer?${query}`, {}, headers);
  }

  public getStats(collectionId: number) {
    const headers = {
      "api-key": PARAMSV2.apiKeyConnect,
    };
    return nftCaller(
      "get",
      `/v1/nft/collection/${collectionId}/stats`,
      {},
      headers
    );
  }
}
