import { createRouter, createWebHistory } from "vue-router";

import HomePage from "@/components/Layout/index.vue";
import NFTRouter from "@/views/nft/router";
import MintRouter from "@/views/nft/router/mint";
import GameRouter from "../views/game/router";
import ProfileRouter from "@/views/profile/router";
const routes = [
  {
    path: "/payment",
    name: "payment",
    component: () => import("@/views/payment/index.vue"),
    meta: {
      pageName: "Payment",
    },
    children: [],
  },
  {
    path: "/deposit",
    name: "deposit",
    component: () => import("@/views/payment/deposit.vue"),
    meta: {
      pageName: "Deposit",
    },
    children: [],
  },
  {
    path: "/pay",
    name: "paymentwebversion",
    component: () => import("@/views/payment/webversion.vue"),
    meta: {
      pageName: "Payment Gateway",
    },
    children: [],
  },
  ...MintRouter,
  {
    path: "/paymentbs",
    name: "paymentbs",
    component: () => import("@/views/payment/basic.vue"),
    meta: {
      pageName: "Payment Basic",
    },
    children: [],
  },
  {
    path: "/whitelist",
    name: "whitelist",
    component: () => import("@/views/whitelist/index.vue"),
    redirect: "/whitelist/about",
    children: [
      {
        path: "about",
        name: "about",
        component: () => import("@/views/whitelist/page/about.vue"),
        meta: {
          pageName: "Whitelist - About",
        },
      },
      {
        path: "connect",
        name: "connect",
        component: () => import("@/views/whitelist/page/connect.vue"),
        meta: {
          pageName: "Whitelist - Connect",
        },
      },
    ],
  },

  {
    path: "/nosupport",
    name: "nosupport",
    component: () => import("@/NoSupport.vue"),
    children: [],
    meta: {
      pageName: "No Support",
    },
  },
  {
    path: "/",
    name: "home",
    component: HomePage,
    redirect: "/collection",
    meta: {
      twModalView: true,
    },
    children: [
      {
        path: "portfolio",
        name: "portfolio",
        component: () => import("@/views/portfolio/index.vue"),
        meta: {
          isFooter: true,
          pageName: "Portfolio",
        },
      },
      ...NFTRouter,
      ...ProfileRouter,
      {
        path: "swap",
        name: "swap",
        component: () => import("@/views/Swap/index.vue"),
        meta: {
          isFooter: true,
          pageName: "Swap",
        },
      },
      {
        path: "ico",
        name: "ico",
        component: () => import("@/views/ico/index.vue"),
        meta: {
          isFooter: true,
          pageName: "Launchpad",
        },
      },
      {
        path: "/ico/detail",
        name: "icodetail",
        component: () => import("@/views/ico/detail.vue"),
        meta: {
          isFooter: false,
          pageName: "Detail",
        },
      },
      {
        path: "/ico/airdrop",
        name: "icoairdrop",
        component: () => import("@/views/ico/airdrop.vue"),
        meta: {
          isFooter: false,
          pageName: "Airdrop",
        },
      },
      {
        path: "/ico/distributions",
        name: "icodistributions",
        component: () => import("@/views/ico/distributions.vue"),
        meta: {
          isFooter: false,
          isHeader: false,
          pageName: "Distributions",
        },
      },
      {
        path: "/ico/import",
        name: "importdistributions",
        component: () =>
          import("@/views/ico/components/distribution/import.vue"),
        meta: {
          isFooter: false,
          isHeader: false,
          pageName: "Update Distribution List",
        },
      },

      {
        path: "bridge",
        name: "bridge",
        component: () => import("@/views/BridgePage/indexComming.vue"),
        meta: {
          isFooter: true,
          pageName: "Bridge",
        },
      },
      ...GameRouter,
    ],
  },
] as any;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
