import { defineComponent, onBeforeUnmount, onMounted, provide, ref, watch } from "vue";
import Alert from "@/components/Alert/index.vue";
import { useTelegram } from "@/hooks/useTelegram";
import Footer from "@/components/Footer/index.vue";
import Header from "@/components/Header/header.vue";
import { Vue3Snackbar, useSnackbar } from "vue3-snackbar";
import { TonConnectUI, toUserFriendlyAddress } from "@tonconnect/ui";
import { useRoute, useRouter } from "vue-router";
import WebApp from "@twa-dev/sdk";
import { PARAMSV2 } from "@/utils/constantsV2";
import { progress } from "@/store/mock/ico";
import { useEventBus } from "@vueuse/core";
import { ConnectService } from "@/views/whitelist/services/connect";
import { useNftProcessStore } from "@/store";
import { NFTInfoModel } from "@/models/NFTInfoModel";
import { typeProcessNft } from "@/models/NFTProcssModel";
import { getUnixNow, getUnixUTCFromUTC } from "@/utils/date";
import { NftService } from "@/views/nft/services";
import { AuthService } from "@/services/authService";
import { errorCodes } from "@/utils/errorCode";
import { storeToRefs } from "pinia";
const {
  platform,
  showMainButton,
  enableClose,
  disableMainButton,
  expand,
  setButtonLoader,
  getViewportHeight
} = useTelegram();
import { useAlertStore } from "@/store";
import { useTokenStore } from "@/store";
export default defineComponent({
  components: {
    Alert,
    Footer,
    Header,
    Vue3Snackbar
  },
  beforeRouteEnter: async (to, from, next) => {
    if (to.name != "nosupport") {
      if (platform !== "unknown") {
        switch (platform) {
          case "web":
          case "weba":
            if (process.env.VUE_APP_WEB == "false") {
              next("/nosupport");
            } else {
              next();
            }
            break;
          default:
            next();
            break;
        }
      } else {
        if (process.env.VUE_APP_WEB == "false") {
          next("/nosupport");
        } else {
          next();
        }
      }
    } else {
      next();
    }
  },
  setup() {
    // cảnh báo warning trước khi đóng
    enableClose();
    const router = useRouter();
    const route = useRoute();
    const currentIsConnectedStatus = ref(false);
    const connector = ref(null);
    const accountInfo = ref(null);
    const walletInfo = ref(null);
    const friendlyAddress = ref("");
    const layoutHeader = ref(null);
    const layoutContainer = ref(null);
    const callSize = () => {
      const viewport = getViewportHeight();
      let height = viewport - 60;
      if (!isHeader.value) {
        height = viewport;
      }
      layoutContainer.value.style.height = `${height}px`;
      document.body.style.minHeight = `${viewport}px`;
    };
    document.addEventListener("resize", callSize);
    onBeforeUnmount(() => {
      document.removeEventListener("resize", callSize);
    });
    onMounted(() => {
      callSize();
    });
    watch(currentIsConnectedStatus, () => {
      const inter = setInterval(() => {
        callSize();
      }, 500);
      setTimeout(() => {
        clearInterval(inter);
      }, 2000);
    }, {
      immediate: true
    });
    const isExpanded = ref(false);
    const checkExpand = () => {
      isExpanded.value = WebApp.isExpanded;
    };
    WebApp.onEvent("viewportChanged", checkExpand);
    onBeforeUnmount(() => {
      WebApp.offEvent("viewportChanged", checkExpand);
    });
    watch(isExpanded, () => {
      setTimeout(() => {
        callSize();
      }, 500);
    });
    provide("isConnect", currentIsConnectedStatus);
    provide("accountInfo", accountInfo);
    provide("friendlyAddress", friendlyAddress);
    provide("walletInfo", walletInfo);
    const getFriendlyAddress = () => {
      const rawAddress = connector.value.wallet.account.address; // like '0:abcdef123456789...'
      const str = toUserFriendlyAddress(rawAddress);
      friendlyAddress.value = str;
    };
    const getDirectParams = () => {
      const data = new URLSearchParams(WebApp.initData);
      const parsedData = Object.fromEntries(data);
      const start_param = parsedData.start_param;
      if (start_param) {
        return start_param;
      } else {
        return "";
      }
    };
    const getLaunchPadSymbol = () => {
      const id = route.query.id;
      let obj;
      if (id) {
        obj = progress.find(f => f.id == Number(id));
      } else {
        const token = getDirectParams();
        if (token) {
          obj = progress.find(f => f.symbol.toLowerCase() === token.toLowerCase());
        }
      }
      return obj;
    };
    const getSlug = () => {
      let slug;
      // if user select item open
      const id = route.query.id;
      if (id) {
        slug = id;
      } else {
        // if user open direct link tele
        const start = getDirectParams();
        if (start) {
          const arr = start.split("_");
          if (arr.length > 0) {
            slug = arr[0];
          }
        }
      }
      return slug;
    };
    const getCalbackUrl = () => {
      let url = PARAMSV2.telegramWebUrl + `?startapp`;
      if (route.name == "icodetail") {
        const obj = getLaunchPadSymbol();
        if (obj) url = PARAMSV2.telegramLaunchpad + `?startapp=${obj.symbol}`;
      } else if (route.name == "icoairdrop") {
        const slug = getSlug();
        if (slug) url = PARAMSV2.telegramAirdrop + `?startapp=${slug}`;
      } else if (route.name == "importdistributions") {
        const slug = getSlug();
        if (slug) url = PARAMSV2.telegramImport + `?startapp=${slug}`;
      } else if (route.name == "gamelottery") {
        const id = getSlug();
        if (id) url = PARAMSV2.telegramGame + `?startapp=${id}`;
      }
      return url;
    };
    const accessToken = ref("");
    const tokenStore = useTokenStore();
    const authService = new AuthService();
    const setAuthorized = async w => {
      if (!w) {
        accessToken.value = "";
        tokenStore.removeToken();
        generatePayload();
        return;
      }
      if (w.connectItems?.tonProof && "proof" in w.connectItems.tonProof) {
        await checkProof(w.connectItems.tonProof.proof, w.account);
      }
      // get token from store
      accessToken.value = await tokenStore.checkToken();
      // if token not fund
      // if (!accessToken.value) {
      //   connector.value.disconnect();
      //   return;
      // }
      // update data
      updateConnectData(currentIsConnectedStatus.value);
    };
    // call api get payload auth
    const generatePayload = async () => {
      const result = await authService.generatePayload();
      if (result && result.statusCode == errorCodes.success) {
        return result.data.payload;
      }
      return "";
    };
    // call api check payload
    const checkProof = async (proof, account) => {
      const reqBody = {
        address: account.address,
        proof: {
          ...proof,
          stateInit: account.walletStateInit
        }
      };
      const result = await authService.checkProof(reqBody);
      if (result && result.statusCode == errorCodes.success) {
        // add to storage
        tokenStore.addToken(result.data.token);
      }
    };
    const recreateProofPayload = async () => {
      if (connector.value) {
        const payload = await generatePayload();
        if (payload.length > 0) {
          connector.value.setConnectRequestParameters({
            state: "ready",
            value: {
              tonProof: payload
            }
          });
        } else {
          connector.value.setConnectRequestParameters(null);
        }
      } else {
        console.error("TonConnectUI instance is not available.");
      }
    };
    const getConnector = async () => {
      const url = getCalbackUrl();
      connector.value = new TonConnectUI({
        manifestUrl: PARAMSV2.manifestUrl,
        actionsConfiguration: {
          twaReturnUrl: `https://${url}`
        }
      });
      const unsubscribe = connector.value.onStatusChange(info => {
        if (info === null) {
          currentIsConnectedStatus.value = false;
          accountInfo.value = null;
          walletInfo.value = null;
          friendlyAddress.value = "";
          // remove token
          tokenStore.removeToken();
        } else if (info.account) {
          accountInfo.value = info.account;
          walletInfo.value = connector.value.wallet;
          getFriendlyAddress();
          // verify tonproof
          setAuthorized(info);
          currentIsConnectedStatus.value = connector.value.connected;
        }
      });
      connector.value.restoreConnection();
      unsubscribe();
    };
    const showModalConnect = async () => {
      if (!currentIsConnectedStatus.value) await connector.value.openModal();
    };
    const updateStatusMainButton = () => {
      if (currentIsConnectedStatus.value) {
        if (route.name != "icodetail") {
          WebApp.MainButton.hide();
        } else {
          disableMainButton("Connected");
        }
        WebApp.MainButton.hideProgress();
      } else {
        // disable event
        WebApp.offEvent("mainButtonClicked", showModalConnect);
        // khởi tạo lại event
        showMainButton("Connect Wallet", showModalConnect);
      }
      setButtonLoader(false);
    };
    watch(currentIsConnectedStatus, () => {
      setButtonLoader(true);
      updateStatusMainButton();
    }, {
      immediate: true
    });
    const isFooter = ref(true);
    const isHeader = ref(true);
    watch(() => router.currentRoute.value, () => {
      updateStatusMainButton();
      const footer = router.currentRoute.value.meta.isFooter;
      isFooter.value = footer && footer == true;
      const header = router.currentRoute.value.meta.isHeader;
      if (header != undefined && header == false) {
        isHeader.value = false;
      } else {
        isHeader.value = true;
      }
      setTimeout(() => {
        callSize();
      }, 500);
    }, {
      immediate: true
    });
    onMounted(() => {
      expand();
      setTimeout(() => {
        getConnector();
      });
    });
    const onSelectConnect = async () => {
      await connector.value.openModal();
      recreateProofPayload();
    };
    // update user connect to be
    const connectService = new ConnectService();
    const updateConnectData = async isConnect => {
      try {
        const data = new URLSearchParams(WebApp.initData);
        const parsedData = Object.fromEntries(data);
        const user = JSON.parse(parsedData.user);
        const friendlyAddress = toUserFriendlyAddress(connector.value.account.address);
        const token = await tokenStore.getToken();
        connectService.connect(token, user.id.toString(), friendlyAddress, isConnect, null, null);
      } catch (ex) {
        //
      }
    };
    provide("tonConnect", connector);
    let unsubscribeModal;
    watch(currentIsConnectedStatus, () => {
      if (currentIsConnectedStatus.value) {
        if (unsubscribeModal) {
          unsubscribeModal();
        }
      } else {
        setTimeout(() => {
          if (!unsubscribeModal && connector.value) unsubscribeModal = connector.value.onModalStateChange(state => {
            if (state.status == "opened") {
              WebApp.MainButton.color = "#98989e";
              WebApp.MainButton.textColor = "#1c1c1d";
              WebApp.MainButton.hide();
            } else {
              WebApp.MainButton.color = "#affb2c";
              WebApp.MainButton.textColor = "#1c1c1d";
              WebApp.MainButton.show();
            }
          });
        }, 1000);
      }
    }, {
      immediate: true
    });
    // user store pinia
    const useProcess = useNftProcessStore();
    // get data from localstorage
    useProcess.getLocal();
    const {
      nfts
    } = storeToRefs(useProcess);
    const transactions = ref({});
    const snackbar = useSnackbar();
    const service = new NftService();
    const busdetail = useEventBus("detailnft");
    const busauction = useEventBus("auctionftstatus");
    const processObject = async () => {
      for await (const element of nfts.value) {
        if (!transactions.value[element.onchainId]) {
          transactions.value[element.onchainId] = {
            intervalId: null,
            info: element
          };
        }
      }
      Object.keys(transactions.value).forEach(txName => {
        const tx = transactions.value[txName];
        if (tx.intervalId == null) {
          tx.intervalId = setInterval(async () => {
            let isComplete = false;
            const result = await service.nftDetail(tx.info.collectionId, tx.info.onchainId);
            if (result && errorCodes.success) {
              const m = new NFTInfoModel(result.data);
              // check price after change
              if (tx.info.type == typeProcessNft.change) {
                if (m.sale.price != 0 && m.sale.price == tx.info.price) {
                  isComplete = true;
                }
              }
              // compare owner after buy
              if (tx.info.type == typeProcessNft.buy) {
                if (m.owner == friendlyAddress.value) {
                  isComplete = true;
                }
              }
              // put on sale
              // check have seller
              if (tx.info.type == typeProcessNft.sale) {
                if (m.sale.saleAddress.length > 0) {
                  isComplete = true;
                } else {
                  isComplete = false;
                }
              }
              // check no have seller
              if (tx.info.type == typeProcessNft.cancel) {
                if (m.sale.saleAddress.length == 0) {
                  isComplete = true;
                } else {
                  isComplete = false;
                }
              }
              // check offer
              if (tx.info.type == typeProcessNft.offer) {
                const myOffer = m.offers.filter(f => f.offeror == friendlyAddress.value);
                if (myOffer.length > 0) {
                  const newOffer = myOffer[0];
                  if (newOffer.offeror == friendlyAddress.value) {
                    isComplete = true;
                  } else {
                    isComplete = false;
                  }
                } else {
                  isComplete = false;
                }
              }
              // check accept offer
              if (tx.info.type == typeProcessNft.acceptoffer) {
                if (m.owner != friendlyAddress.value) {
                  isComplete = true;
                }
              }
              // check cancel offer
              if (tx.info.type == typeProcessNft.canceloffer) {
                const myOffer = m.offers.find(f => f.id == tx.info.offerId);
                if (!myOffer) {
                  isComplete = true;
                } else {
                  isComplete = false;
                }
              }
              // check push on auction
              if (tx.info.type == typeProcessNft.auction) {
                if (m.sale.type == "AUCTION") {
                  isComplete = true;
                } else {
                  isComplete = false;
                }
              }
              if (tx.info.type == typeProcessNft.cancelAuction) {
                if (m.sale.type != "AUCTION") {
                  isComplete = true;
                } else {
                  isComplete = false;
                }
              }
              if (tx.info.type == typeProcessNft.endAuction) {
                if (m.sale.type != "AUCTION" && (m.owner == friendlyAddress.value || m.owner == tx.info.ownerAddress)) {
                  isComplete = true;
                } else {
                  isComplete = false;
                }
              }
              // bid auction
              if (tx.info.type == typeProcessNft.bid) {
                // bid or buyouty
                const mybids = m.sale.bids.filter(f => f.bidder == friendlyAddress.value);
                if (mybids.length > 0) {
                  const newBid = mybids[0];
                  const bidTime = getUnixUTCFromUTC(newBid.bidTime);
                  const current = getUnixNow();
                  if (bidTime + 360 >= current) {
                    isComplete = true;
                  }
                } else if (m.owner == friendlyAddress.value) {
                  isComplete = true;
                } else {
                  isComplete = false;
                }
              }
              if (isComplete && tx) {
                const intervalId = tx.intervalId;
                if (intervalId) clearInterval(intervalId);
                let message = "";
                if (tx.info.type == typeProcessNft.change) {
                  message = "Change price completed";
                }
                if (tx.info.type == typeProcessNft.buy) {
                  message = `Buy ${tx.info.name} completed`;
                }
                if (tx.info.type == typeProcessNft.cancel) {
                  message = "Remove from sales completed";
                }
                if (tx.info.type == typeProcessNft.sale) {
                  message = "Put on sale completed";
                }
                if (tx.info.type == typeProcessNft.auction) {
                  message = "Put on auction completed";
                }
                if (tx.info.type == typeProcessNft.cancelAuction) {
                  message = "End auction completed";
                }
                if (tx.info.type == typeProcessNft.bid) {
                  message = "Bid completed";
                }
                if (tx.info.type == typeProcessNft.endAuction) {
                  message = "End auction completed";
                }
                if (tx.info.type == typeProcessNft.offer) {
                  message = "Offer completed";
                }
                if (tx.info.type == typeProcessNft.acceptoffer) {
                  message = "Accept offer completed";
                }
                if (tx.info.type == typeProcessNft.canceloffer) {
                  message = "Cancel offer completed";
                }
                snackbar.add({
                  type: "success",
                  text: message,
                  duration: 3000
                });
                busdetail.emit(m);
                tx.intervalId = null;
                useProcess.remove(txName);
                delete transactions.value[txName];
              }
            }
          }, 10000);
        }
      });
    };
    let intervalRemove = null;
    const processRemoveItem = () => {
      if (intervalRemove != null) {
        clearInterval(intervalRemove);
      }
      if (nfts.value.length > 0) {
        intervalRemove = setInterval(() => {
          const current = getUnixNow();
          const ls = nfts.value.filter(f => f.expired < current);
          if (ls.length > 0) {
            ls.forEach(element => {
              useProcess.remove(element.onchainId);
              if (transactions.value[element.onchainId]) {
                clearInterval(transactions.value[element.onchainId].intervalId);
                delete transactions.value[element.onchainId];
              }
            });
          }
          if (nfts.value.length == 0) {
            clearInterval(intervalRemove);
            intervalRemove = null;
          }
        }, 5000);
      }
    };
    const clearTransactionInterval = () => {
      Object.keys(transactions.value).forEach(txName => {
        if (transactions.value[txName]) clearInterval(transactions.value[txName].intervalId);
      });
      transactions.value = {};
    };
    onBeforeUnmount(() => {
      clearTransactionInterval();
      if (intervalRemove) {
        clearInterval(intervalRemove);
        intervalRemove = null;
      }
    });
    watch(() => nfts.value.length, () => {
      if (nfts.value.length > 0) {
        processObject();
        // interval remove item afterr 60s
        processRemoveItem();
      } else {
        clearTransactionInterval();
      }
    }, {
      immediate: true
    });
    // emit connect
    const bus = useEventBus("selectconnect");
    bus.on(onSelectConnect);
    onBeforeUnmount(() => {
      bus.off(onSelectConnect);
    });
    const onSelectDisconnect = () => {
      connector.value.disconnect();
      // clear process nfts
      useProcess.removeAll();
      // clear token
      tokenStore.removeToken();
    };
    const useAlert = useAlertStore();
    watch(() => useAlert.alert, () => {
      if (useAlert.alert) {
        snackbar.add({
          type: useAlert.type,
          text: useAlert.message,
          duration: 2000
        });
        useAlert.$patch({
          alert: false,
          message: "",
          type: "success"
        });
      }
    });
    return {
      isFooter,
      isHeader,
      connector,
      onSelectConnect,
      onSelectDisconnect,
      layoutHeader,
      layoutContainer
    };
  }
});