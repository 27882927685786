import { apiCaller } from "@/api/api-helper";

export class AuthService {
  public generatePayload() {
    return apiCaller("post", `/v1/auth/generate-payload`, {}, null, false);
  }

  public checkProof(data) {
    return apiCaller("post", `/v1/auth/check-proof`, data, null, false);
  }
}
