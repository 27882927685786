export class NFTMakeOffer {
  nftAddress: string;
  offeror: string;
  price: number;
  expiredAt: string;
  allowOffer: boolean;

  constructor() {
    this.nftAddress = "";
    this.offeror = "";
    this.price = 0;
    this.expiredAt = "";
    this.allowOffer = false;
  }
}
